import React from "react";

const NOtFound = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center", marginTop: `20px` }}>Page Not Found</h1>
    </div>
  );
};

export default NOtFound;
